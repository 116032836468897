import React from "react"
import { Flex, Box } from "rebass"
import Figure from "../components/Figure"
import { H1, H3, P, Ul } from "../components/VerticalRhythm"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import RespGrid from "../components/RespGrid"
import breakpoints from "../theme/breakpoints"

import PictureSet from "../components/PictureSet"
import imgObj10 from "../imgObjs/imgObj10"

const ImprintPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <Figure id={"master"} caption="">
      <PictureSet imgObj={imgObj10} />{" "}
    </Figure>
    <Section>
      <H1>Impressum</H1>
      <RespGrid min={"240px"}>
        <Box>
          <H3>Verantwortlich</H3>
          <P>
            Geesche Martin
            <br />
            Psychosoziale Beraterin M.A.
            <br />
            Butzbacher Str. 25
            <br />
            60389 Frankfurt
            <br />
            info@krisenberatung-frankfurt.com
            <br />
            069-599761
            <br />
            <br />
            <strong>Berufshaftpflichtversicherung</strong>
            <br />
            hevianna Versicherungsdienst GmbH
            <br />
            Werner-Eckert-Straße 11
            <br />
            81829 München
          </P>
        </Box>
        <Box>
          <H3>Danksagung</H3>
          <div>
            Logo kreiert von{" "}
            <a
              href="https://webseiten-anders.de/"
              title="Logo"
              target="_blank"
              rel="noopener"
              rel="nofollow"
            >
              Karl Anders
            </a>
            .
          </div>
          <p>
            Bilder von{" "}
            <a
              href="https://unsplash.com/"
              title="Unsplash"
              target="_blank"
              rel="noopener"
              rel="nofollow"
            >
              Unsplash
            </a>{" "}
            und{" "}
            <a
              href="https://pixabay.com"
              title="pixabay"
              target="_blank"
              rel="noopener"
              rel="nofollow"
            >
              pixabay
            </a>
            .
          </p>
        </Box>
      </RespGrid>
    </Section>
  </Layout>
)

export default ImprintPage
